import Alpine from 'alpinejs';
import hcSticky from 'hc-sticky';
import { Fancybox } from "@fancyapps/ui";
function init() {

  window.Fancybox = Fancybox;
  var elements = document.querySelectorAll('.pd-sticky-el');

  for (var i = 0; i < elements.length; i++) {
    new hcSticky(elements[i], {
      stickTo: elements[i].parentNode,
      top: 80,
      bottomEnd: 0,
    });
  }

  var elements = document.querySelectorAll('.pd-sticky-header');

  for (var i = 0; i < elements.length; i++) {
    new hcSticky(elements[i], {
      stickTo: elements[i].parentNode,
      top: 0,
      bottomEnd: 0,
    });
  }

  $(window).scroll(function() {
    var windowBottom = ($(this).scrollTop() + $(this).innerHeight());


    $("#pd-header-content").each(function() {
      /* Check the location of each desired element */
      var objectBottom = $(this).offset().top + 300;

      console.log('window: ' + windowBottom);
      console.log('objectBottom: ' + objectBottom);
      /* If the element is completely within bounds of the window, fade it in */
      if (objectBottom < windowBottom) { //object comes into view (scrolling down)
        if ($('#pd-header-background').css("opacity")==1) {$('#pd-header-background').fadeTo(500,0);}
      } else { //object goes out of view (scrolling up)
        if ($('#pd-header-background').css("opacity")==0) {$('#pd-header-background').fadeTo(500,1);}
      }
    });

    $("#pd-stats-trigger").each(function() {
      /* Check the location of each desired element */
      var objectBottom = $(this).offset().top + $(this).outerHeight() + 300;


      /* If the element is completely within bounds of the window, fade it in */
      if (objectBottom < windowBottom) { //object comes into view (scrolling down)
        if ($('#pd-stats-background').css("opacity")==0) {$('#pd-stats-background').fadeTo(500,1);}
      } else { //object goes out of view (scrolling up)
        if ($('#pd-stats-background').css("opacity")==1) {$('#pd-stats-background').fadeTo(500,0);}

      }
    });



  }).scroll(); //invoke scroll-handler on page-load
  // var controller = new ScrollMagic.Controller();
  //
  //
  // var scene = new ScrollMagic.Scene({triggerElement: ".pd-trigger"})
  //     .setPin(".pd-scroll")
  //     .addIndicators({name: "1 (duration: 300)"}) // add indicators (requires plugin)
  //     .addTo(controller);

  window.Alpine = Alpine;
  Alpine.start();

  $('a[href^="#"], button[href^="#"]').on('click', function(e) {
    e.preventDefault();

    const scrollTop = $($(this).attr('href')).position().top - 50;

    $('html, body').animate({ scrollTop });
  })
}

window.addEventListener("load", function () {
  init(); //
});
